import CartButton from '@/components/CartButton';
import { useDrawer } from '@/hooks';
import { Link } from '@holo/link';
import { Button, Container, Text, VisuallyHidden, XStack, YStack } from '@holo/ui';
import { Logo } from '@holo/ui/assets';
import { useRouter } from 'next/router';

const INCLUDE_BOOK_A_MEETING_LINK_ROUTES = ['/platform'];
const INCLUDE_FOR_PRODUCERS_LINK_ROUTES = ['/'];
const EXCLUDE_CART_BUTTON_ROUTES = ['/checkout', '/platform'];

const MobileHeader = () => {
  const { open: openMobilMenu } = useDrawer('mobile-menu');
  const { route } = useRouter();

  const shouldRenderBookAMeetingLink = INCLUDE_BOOK_A_MEETING_LINK_ROUTES.includes(route);
  const shouldRenderForProducersLink = INCLUDE_FOR_PRODUCERS_LINK_ROUTES.includes(route);
  const shouldRenderCartButton = !EXCLUDE_CART_BUTTON_ROUTES.includes(route);

  return (
    <YStack
      className="header"
      // @ts-ignore
      position="sticky"
      top={0}
      zIndex={99}
      backgroundColor="hsla(0, 0%, 100%, .8)"
    >
      <XStack display="flex" $md={{ display: 'none' }} zIndex={99} py="$3" alignItems="center">
        <Container flexDirection="row" alignItems="center">
          <XStack alignItems="center" space="$4">
            <Button
              variant="quartenary"
              icon="menu"
              iconSize={4 * 5}
              label="Open menu"
              width="$10"
              height="$10"
              onPress={openMobilMenu}
            />
            <Link href="/">
              <VisuallyHidden>
                <Text>Go to homepage</Text>
              </VisuallyHidden>
              <Logo />
            </Link>
          </XStack>
          {(shouldRenderBookAMeetingLink || shouldRenderForProducersLink || shouldRenderCartButton) && (
            <XStack ml="auto" alignItems="center" space="$4">
              {shouldRenderBookAMeetingLink && (
                <a href="https://calendly.com/holoag/30min" target="_blank" rel="noopener noreferrer">
                  <Text
                    display="flex"
                    jc="center"
                    ai="center"
                    height="$10"
                    px="$4"
                    backgroundColor="$contrast"
                    hoverStyle={{ backgroundColor: '$contrastHover' }}
                    color="$base"
                    borderRadius={10000}
                  >
                    Book a meeting
                  </Text>
                </a>
              )}

              {shouldRenderCartButton && <CartButton />}
            </XStack>
          )}
        </Container>
      </XStack>
    </YStack>
  );
};

export default MobileHeader;
